@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
body {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0;
  font-family: 'Monsterrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6fb;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


header {
  margin: 0;
  text-align: center;
  max-height: 20%;
}

header h1 {
  margin: 0;
  font-size: calc((3em + 15vw)/ 4);
  color: #000000e0;
}

header h3 {
  margin: 0;
  font-size: calc((1em + 5vw)/ 4);
}

.leaflet-container {
  height: 80vh;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 90vw;
  margin: auto;
}

.leaflet-popup-content {
  text-align: center;
}
