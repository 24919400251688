
header {
  margin: 0;
  text-align: center;
  max-height: 20%;
}

header h1 {
  margin: 0;
  font-size: calc((3em + 15vw)/ 4);
  color: #000000e0;
}

header h3 {
  margin: 0;
  font-size: calc((1em + 5vw)/ 4);
}

.leaflet-container {
  height: 80vh;
  flex: 1;
  width: 90vw;
  margin: auto;
}

.leaflet-popup-content {
  text-align: center;
}